<template>
    <div>
        <LoaderDefault v-if="this.isLoading" />
        <div v-else>
            <DefaultHeader2 :routeBack="'/taips'" :titleHeader="'Formulario de registro'" />
            <section class="content-form">
                <div class="content-inputs" id="content-inputs">
                    <label for="title" id="number-pocess-label">Titulo *</label>

                    <input v-model="formData.title" class="input" name="title" type="text"
                        placeholder="Titulo del la transaparencia y acceso" id="title" required>
                    <span v-if="errors.title" class="error-message">{{ errors.title }}</span>
                </div>

                <div class="content-inputs" id="content-inputs">
                    <label for="name_of_item">Item *</label>
                    <input v-model="formData.data[0].subtitle" class="input" name="name_of_item" type="text"
                        placeholder="Nombre del item" id="name_of_item" required>
                    <span v-if="errors.subtitle" class="error-message">{{ errors.subtitle }}</span>
                </div>
            </section>
            <hr>
            <!-- section-anexos -->
            <section class="sec-anexos" id="sec-anexos">
                <div class="header-sec-anexos">
                    <h1 class="title-section-anexos">Subir documentos</h1>
                    <i class="fa-solid fa-circle-info"></i>
                </div>

                <div class="content-list-items-add">
                    <div v-for="(tag, index) in tagsTaip" :key="index" >
                        <div class="content-item">
                            <h2 class="name-item-added">{{ tag.title}}</h2>
                            <i class="fa-sharp fa-solid fa-file-pdf icon-pdf"></i>
                            <i class="el-icon-close text-danger icon-added" @click="showConfirmation(tag)"></i>
                        </div>
                    </div>
                    <!-- <el-tag v-for="(tag, index) in tagsTaip" :key="index" closable :disable-transitions="false"
                        @close="showConfirmation(tag)">
                        {{ tag.title }}
                    </el-tag> -->
                </div>
                <div class="content-form">
                    <div class="content-inputs" id="content-inputs">
                        <label class="label" id="label" for="name">Nombre *</label>
                        <input id="name" v-model="dataDocumentTaip.title" class="input input-anexo" name="name" type="text"
                            placeholder="Nombre" required>
                        <span v-if="errorsItems.name" class="error-message">{{ errorsItems.name }}</span>
                    </div>

                    <div class="div-select-custom">
                        <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()" name="file-5"
                            class="inputfile inputfile-5 input-anexo" required />
                        <label id="label" for="file-5" class="label-file label">
                            <figure>
                                <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20" height="17"
                                    viewBox="0 0 20 17">
                                    <path
                                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                    </path>
                                </svg>
                            </figure>
                        </label>
                        <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar archivo *</h6>
                        <span v-if="errorsItems.file" class="error-message">{{ errorsItems.file }}</span>
                    </div>
                </div>
                <button class="btn-save-item" @click="addItemAnexoToArray()">Subir Documento</button>
            </section>
            <hr>
            <button class="btn-save-data" @click="submitForm()">Guardar Archivo</button>
        </div>
    </div>
</template>

<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import { db, storage } from '../../../../firebaseConfig';
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import dayjs from 'dayjs';

export default {

    components: {
        DefaultHeader2,
        LoaderDefault,
    },
    data() {
        return {
            property: 'value',
            tagsTaip: [],
            isLoading: false,
            selectedFile: File,

            dataDocumentTaip: {
                _idTitle: "_id",
                title: '',
                file: File,
                urlPdf: ""
            },

            formData: {
                _id: '_id',
                title: '',
                createAt: dayjs().format('DD-MM-YYYY'),
                data: [
                    {
                        subtitle: '',
                        _idSubtitle: '',
                        data: []
                    }
                ]
            },
            errors: {},
            errorsItems: {}

        };
    },
    methods: {
        submitForm() {
            this.errors = {};
            if (!this.validateForm()) {
                return;
            }
            this.validateIfExistAccess()
        },
        validateForm() {
            let isValid = true;

            if (!this.formData.title != '') {
                this.errors.title = 'Por favor, introduce tu nombre.';
                isValid = false;
            }

            if (!this.formData.data[0].subtitle != '') {
                this.errors.subtitle = 'Por favor, introduce item.';
                isValid = false;
            }
            return isValid;
        },
        validateFormItems() {
            let isValid = true;

            if (!this.dataDocumentTaip.title != '') {
                this.errorsItems.name = 'Por favor, introduce un nombre.';
                isValid = false;
            }

            if (!(this.selectedFile instanceof File && this.selectedFile.size > 0)) {
                this.errorsItems.file = 'Por favor, selecciona un archivo.';
                isValid = false;
            }
            return isValid;
        },

        navToIndexTender() { this.$router.push('/taips') },

        addItemAnexoToArray() {
            this.errorsItems = {};
            if (!this.validateFormItems()) {
                return;
            }
            this.dataDocumentTaip.file = this.selectedFile
            this.tagsTaip.push(this.dataDocumentTaip)
            this.dataDocumentTaip = {}
            const nameSelected = document.getElementById('name-pdf-selected');
            nameSelected.innerHTML = 'Seleccionar archivo *';
        },

        removeItemAnexoToArray(itemAnexo) {
            const index = this.tagsTaip.indexOf(itemAnexo);
            if (index > -1) this.tagsTaip.splice(index, 1);
        },

        loadFilePdf() {
            const input = document.getElementById('file-5')
            input.accept = "application/pdf";
            input?.addEventListener('change', (e) => {
                const target = e.target;
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                }
            });
        },
        async validateIfExistAccess() {
            if (this.tagsTaip.length > 0) {
                this.isLoading = true
                const query = await db.collection('taips').where('title', '==', this.formData.title)
                query.get().then((res) => {
                    if (!res.empty) {
                        this.$swal.fire({
                            title: 'Información importante',
                            html: `El nombre del acceso <span style="color: red">${this.formData.title}</span> ya existe en la base de datos. Por favor, ingrese un valor diferente.`,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                            cancelButtonText: 'Cancelar',
                        })
                        this.isLoading = false
                    } else {
                        ("EntreUno");
                        this.saveTaip()
                    }
                })
            } else {
                this.$swal
                    .fire({
                        title: 'Información importante',
                        text: 'Para lograr el almacenamiento correcto de una nueva licitación es necesario ' +
                            'agregar minimo un documento',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                        timer: 5000
                    })
            }
        },
        showConfirmation(itemAnexo) {
            this.$swal
                .fire({
                    title: 'Eliminar Documento',
                    text: '¿Estás seguro de realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.removeItemAnexoToArray(itemAnexo);
                });
        },

        async saveTaip() {

            const collectionRef = db.collection('taips');
            const docRef = await collectionRef.add(this.formData);
            const docId = docRef.id;

            const storageRef = storage.ref();
            const anexosPromises = this.tagsTaip.map(async (access) => {
                const pdfRef = storageRef
                    .child('pdfsDocumentsTaips')
                    .child(docId)
                    .child(this.formData.data[0].subtitle)
                    .child(access.title);
                await pdfRef.put(access.file);
                const pdfUrl = await pdfRef.getDownloadURL();
                const accessData = {
                    _idTitle: access.title,
                    title: access.title,
                    urlPdf: pdfUrl
                };
                return accessData;
            });
            const dataPromise = await Promise.all(anexosPromises);
            const snapshot = await collectionRef.doc(docId).get();
            const formData = snapshot.data();
            formData.data[0]._idSubtitle = formData.data[0].subtitle
            formData.data[0].data = dataPromise;
            await collectionRef.doc(docId).update(formData);
            await collectionRef.doc(docId).update({ _id: docId });
            this.$swal
                .fire({
                    title: 'Licitación',
                    text: 'Nueva licitación creada con exito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                })
                .then((result) => {
                    if (result.value) this.navToIndexTender()
                });
        },
    },


    mounted() {

    },
}
</script>